$tecky-blue: #0F3E82;
$tecky-green: #1B7A76;
$tecky-gray: #EAEAEA;
$tecky-dark-gray: #595758;
$tecky-cyan: #22A7A1;
$tecky-light-green:#6EAD59;
$tecky-pink: #D76072;
$tecky-yellow:#f5d142;

$grey-bg: $tecky-gray;
$dark-bg: #1e1e1e;

div.main{
    display: flex;
    justify-content:flex-start;
    flex-direction: column;
    position: absolute;
    top: 0px;
    left: 0px;
    right:0px;
    bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    overflow-x:hidden
}