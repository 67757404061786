@import '../shop/shop.module.scss';
@import '../stock/stock.module.scss';

div.locationBox{
    display: flex;
    justify-content: flex-end;
    margin-right: 100px;
    button{
        font-size: 15px;
    }
}