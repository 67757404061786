@import './addInventory.module.scss';

div.sectionHalf{
    border: $tecky-green solid 2px;
    //width: 48.5%;
    margin: 8px 8px 8px 8px;
    border-radius: 10px;
    position: relative;
    //height: 100%;
    padding-left: 0px;
    padding-right: 0px;
    //height: 200px;

    div.title{
        background-color: $tecky-green;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        overflow-wrap: break-word;
        color: white;
        font-weight: bold;
        font-size:18px;
    }
    div.content1{
        //position: absolute;
        width: 100%;
        top:0px;
        bottom: 0px;
        div.droppable{
            //position: absolute;
            width: 100%;
            top:48px;
            bottom: 50px;
            overflow-y: scroll;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
            min-height: 206px;
            max-height: 206px;
            div.stockContent{
                border-top-left-radius: 15px;
                border-bottom-right-radius: 15px;
                width: 100%;
                div.stockContentInfo{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-content: center;
                    align-items:center;
                    left: 0px;
                    right: 0px;
                    color: $tecky-green;
                    font-weight: bold;
                    div.foodName{
                        margin: 10px 0px 10px 10px;
                        width: 40%;
                        overflow-wrap: break-word;
                    }
                    div.qty{
                        font-size: 25px;
                    }
                    div.addQty{
                        margin: 10px 10px 10px 0px;
                        .inventoryQtyDrp{
                            width: 150px;
                        }
                    }
                }
            }
        }
        div.listPlaceHolder{
            //height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            span{
                font-weight: bold;
                color: $tecky-green;
                font-size: 2em;
                opacity: 0.3;
                @media screen and (max-width:700px) {
                    font-size: calc(2em - 1px);
                }
                @media screen and (max-width:524px) {
                    font-size: calc(100% - 2px);
                }
            }
            text-align: center;
        }
    }

    div.submit{
        border-top: $tecky-green solid 1px;
        //width: 100%;
        //position: absolute;
        bottom: 0px;
        display: flex;
        justify-content: center;
        background-color: transparent;
        padding-bottom: 5px;
        padding-top:5px;
        margin-left: 5px;
        margin-right: 5px;
        button{
            padding-bottom: 5px;
            padding-top:5px;
            font-size: 24px;
            background-color: $tecky-green;
            color: white;
            border-radius: 10px;
            padding-left: 10px;
            padding-right: 10px;
            border: none;
            &:hover{
                color: $tecky-green;
                border: $tecky-green solid 1px;
                background-color: white;
            }
            &:focus{
                outline-style: none;
            }
            &:active{
                color: $tecky-green;
                border: none;
                background-color: white;
            }
        }
    }
}