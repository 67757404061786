@import '../shop/shop.module.scss';
div.centerMenu{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    height: 12vh;
    width: 12vh;
    border-radius:50%;
    background-color: $tecky-green;
    color: white;
    text-align: center;
    opacity: 0.6;
    
    &:hover{
        border-width: 10px;
        box-shadow: 0px 0px 15px 10px $tecky-cyan;
        cursor: 'pointer';
    }
}

.satellite{
    height: 100px;
    width: 100px;
    border-radius:50%;
    background-color: $tecky-light-green;
    display: flex;
    justify-content:center;
    align-items:center;
    color:white;
    text-align: center;
    //opacity: 80%;
    button{
        background-color: transparent;
        border: none;
        outline: none;
        color:white;
        font-size: 17px;
    }
}