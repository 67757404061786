@import '../shop/shop.module.scss';
@import '../stock/stock.module.scss';

div.contentSection{
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

div.contentSection2{
    height: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}