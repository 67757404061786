@import '../common/common.module.scss';
div.rootContainer{
    position: relative;
    width: 230px;
    height: 230px;
    margin: 0px 0px 0px 0px;
    top:20px;
}

div.indicatorSection{
    position: absolute;
    top: 0px;
    bottom: 30px;
    left: 0px;
    right: 0px;
    div.lower{
        background-color:$tecky-cyan;
    }
    div.upper{
        background-color:$tecky-yellow;
    }
}

div.imgSection{
    position: absolute;
    top:-10px;
    bottom: -10px;
    left: 0px;
    right: 0px;
    img{
        height: 100%;
        width: 100%; 
        object-fit: contain;
    }
}

div.infoSection{
    width: 100%;
    position: absolute;
    top:30px;
    bottom: 50px;
    left: 5px;
    right: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items:center;
    div{
        width: 50%;
        overflow-wrap: break-word;
        font-weight: bold;
        text-align: center;
        font-size: 20px;
    }
}