@import './stockList.module.scss';

div.sectionHalf{
    border: $tecky-green solid 2px;
    //width: 48.5%;
    margin: 8px 8px 8px 8px;
    border-radius: 10px;
    //min-height: 340px;
    position: relative;

    div.title{
        background-color: $tecky-green;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right:10px;
        overflow-wrap: break-word;
        color: white;
        font-weight: bold;
        font-size:18px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    div.content1{
        //position: relative;
        width: 100%;
        top:0px;
        bottom: 0px;
        padding-left: 0px;
        padding-right: 0px;
        div.droppable{
            //position: absolute;
            width: 100%;
            min-height: 250px;
            max-height: 250px;
            top:48px;
            bottom: 0px;
            overflow-y: scroll;
            
            div.stockContent{
                div.stockContentInfo{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-content: center;
                    align-items:center;
                    left: 0px;
                    right: 0px;
                    color: $tecky-green;
                    font-weight: bold;
                    div.foodName{
                        margin: 10px 0px 10px 20px;
                        width: 40%;
                        overflow-wrap: break-word;
                    }
                    div.location{
                        overflow-wrap: break-word;
                    }
                    div.qty{
                        margin: 10px 20px 10px 0px;
                        overflow-wrap: break-word;
                    }
                }
            }
        }
    }
}