@import '../shop/shop.module.scss';
@import '../stock/stock.module.scss';

div.contentSection{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    div.form{
        width: 50%;
        border: $tecky-green dashed 2px;
        border-radius: 10px;
        padding: 10px 10px 10px 10px;
        z-index: 2;

        form{
            width: 100%;
        }

        .formText{
            font-size: 20px;
            //width: 300px;
        }

        input{
            @extend .formText;
        }
        select{
            @extend .formText;
        }
        div.formCol{
            display: flex;
            flex-direction: column;
        }
        div.formRow{
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 10px;
            margin-bottom: 10px;
            label{
                margin-right: 10px;
                font-size: 20px;
            }
        }
        div.fileBg{
            background-color: $tecky-green;
            padding: 5px 5px 5px 5px;
            color: white;
            margin-bottom: 10px;
        }
        div.formRowSubmit{
            display: flex;
            flex-direction: row;
            justify-content: center;
            div.formTitle{
                font-size: 25px;
                font-weight: bold;
                border-bottom: $tecky-green solid 1px;
            }
            margin-bottom: 10px;
        }

        div.formRowFileTop{
            @extend div.formRow;
            border-top: $tecky-green solid 1px;
            padding-top: 10px;
        }
        div.formRowSubmitFile{
            @extend div.formRowSubmit;
        }
        div.formRowFileBottom{
            @extend div.formRow;
            border-bottom: $tecky-green solid 1px;
            padding-bottom: 10px;
        }
    }
}