@import './common.module.scss';
.wrapper {
    position: relative;
  }
  .trigger {
    cursor: pointer;
    display: inline-block;
  }
  
  .dropdownContent {
    position: absolute;
    z-index: 100;
    width: 200px;
    height: 200px;
    font-size: 20px;
    border: $tecky-green solid 1px;
    border-radius: 10px;
    background-color: white;
    overflow-y: scroll;
    overflow-wrap: break-word;
    a{
        font-size: 15px;
    }
  }