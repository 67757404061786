@import '../shop/shop.module.scss';
@import '../stock/stock.module.scss';

.addButton{
    border-radius: 60px 60px 60px 60px;
    font-family: monospace;
    font-size: 16px;
    background-color:$tecky-cyan;
    color: white;
    cursor: pointer;
    border:none;
    width: 200px;
}