@import './shop.module.scss';
.snackSection{
    width: 100%;
    height: 42vh;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding-left: 10px;
    padding-top: 5px;
    padding-right: 10px;
    div.topTitle{
        width: 100%;
        background-color: $tecky-cyan;
        border-radius: 10px;
        padding-top: 10px;
        padding-bottom: 10px;

        span{
            color: white;
            padding-left: 10px;
            font-size: 16px;
            font-weight: bold;
        }
    }

    div.topSectionContentBorder3{
        height: 100%;
        background-color:white;
        border-radius: 10px;
        margin-top: 1vh;
        margin-bottom: 13vh;
        display: flex;
        flex-direction: column;
        position: relative;
        div.foodListSection{
            position: absolute;
            overflow:auto;
            top:0px;
            bottom: 0px;
            left:0px;
            right: 0px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            div.foodContent{
                border-radius: 10px;
                display:flex;
                justify-content: center;
                margin-top: 5px;
                margin-left: 5px;
                width: 31%;
                height: 44%;
                border: $tecky-light-green solid 1px;
                img{
                    object-fit: contain;
                    max-width: 100%;
                    max-height: 100%;
                    width: auto;
                    height: auto;
                }
                position: relative;
                div.foodNameText{
                   border-bottom: white dashed 1px;
                   text-align: center;
                }
            }
            div.foodContent:hover div.img__description{
                visibility: visible;
                opacity: 1;
            }
            div.img__description{
                border-radius: 10px;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: rgba(29, 106, 154, 0.72);
                color: #fff;
                visibility: hidden;
                opacity: 0;
              
                /* transition effect. not necessary */
                transition: opacity .2s, visibility .2s;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
            }
        }
    }
}