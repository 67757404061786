@import '../shop/location.module.scss';

div.loginPanel{
    button{
        background-color:$tecky-cyan;
        color: white;
        font-size: 20px;
        padding: 10px 60px;
        border-radius: 5px;
        margin: 10px 0px;
        cursor: pointer;
        border:none;
        box-shadow: 3px 3px 1px 1px $tecky-dark-gray;
    }
}


div.bottomSection{
    form{
        padding-top: 10px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .rowStyle{
            margin-top: 5px;
            margin-bottom: 5px;
        }
        padding-bottom: 10px;
    }
}
