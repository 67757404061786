@import './shop.module.scss';
.logoSection{
    position: absolute;
    width: 62%;
    img{
        filter: brightness(0) invert(1);
        left: 0px;
        right: 0px;
        top:0px;
        bottom: 0px;
    }
}
.siteNameSection{
    width:80%;
}
.loginSection{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 10px;
    font-weight: bold;
}

.loginButton{
    border-radius: 60px 60px 60px 60px;
    font-family: monospace;
    font-size: 22px;
    background-color:$tecky-cyan;
    color: white;
    //padding: 10px 20px;
    cursor: pointer;
    border:none;
    position: absolute;
    left: 0px;
    right: 0px;
    top:10px;
    bottom: 0px;
}