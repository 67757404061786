@import './addInventory.module.scss';

div.section{
    border: $tecky-green solid 2px;
    //width: 32%;
    margin: 7px 7px 7px 7px;
    border-radius: 10px;
    //position: relative;
    //height: 350px;
}
div.title{
    //position: relative;
    top:0px;
    left:0px;
    right: 0px;
    background-color: $tecky-green;
    z-index: 1;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    overflow-wrap: break-word;
    color: white;
    font-weight: bold;
    font-size:18px;
}
div.content{
    //position: absolute;
    top:41px;
    bottom:0px;
    left:0px;
    right: 0px;
    border-radius: 8px;
    height: 300px;

    div.droppable{
        height: 100%;
        top:0px;
        bottom:0px;
        left:0px;
        right: 0px;
        overflow-y: scroll;
        div.stockContent{
            margin: 10px 15px 15px 15px;
            background-color: $tecky-green;
            border-top-left-radius: 15px;
            border-bottom-right-radius: 15px;
            div.stockContentInfo{
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-content: center;
                align-items:center;
                left: 0px;
                right: 0px;
                color: white;
                font-weight: bold;
                div.foodName{
                    margin: 10px 0px 10px 10px;
                    width: 40%;
                    overflow-wrap: break-word;
                }
                div.qty{
                    font-size: 25px;
                }
                div.addQty{
                    .inventoryQtyDrp{
                        width: 150px;
                    }
                }
                div.confirmBtn{
                    margin: 0px 10px 0px 0px;
                    //padding: 5px 0px 5px 5px;
                    button{
                        padding: 6px 6px 6px 6px;
                        background-color: transparent;
                        color: white;
                        border: white solid 1px;
                        font-size: 15px;
                        &:focus{
                            outline:0px;
                        }
                        &:active{
                            border: none;
                        }
                    }
                }
            }
        }
    }
}