@import '../common/common.module.scss';
div.rootContainer{
    position: relative;
    width: 230px;
    height: 230px;
    margin: 0px 0px 0px 0px;
}

div.indicatorSection{
    position: absolute;
    top: 20px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    div.lower{
        background-color:$tecky-cyan;
    }
    div.upper{
        background-color:$tecky-yellow;
    }
}

div.imgSection{
    position: absolute;
    top:0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    img{
        height: 100%;
        width: 100%; 
        object-fit: contain;
    }
}

div.infoSection{
    width: 100%;
    position: absolute;
    top:0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    //color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    div{
        width: 50%;
        overflow-wrap: break-word;
        //color: white;
        font-weight: bold;
        text-align: center;
        margin-top: 20px;
        font-size: 20px;
    }
}