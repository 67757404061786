@import '../common/common.module.scss';
// body,html{
//     overflow:hidden
//   }

div.root{
    display: flex;
    justify-content:center;
}

div.topSection{
    //position: relative;
    //height: 55%;
    background-image: url('/images/slant_1.png');
    background-size: 100% 100%;
    background-position:center;
    background-repeat: no-repeat;
    z-index: 10;

    display: flex;
    flex-direction:column;
    div.row{
        display: flex;
        flex-direction: row;
        padding-top: 5px;
        align-items: center;
    }
}

div.bottomSection{
    position: relative;
    height: 45%;
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    div.col1{
        width:40%;
        display: flex;
        flex-direction: column;
    }
    div.col2{
        width:60%;
        display: flex;
        flex-direction: column;
    }    
}

div.header{
    //font-size: 30px;
    font-size: calc(4vh - 3px);
    font-weight: bold;
    color: white;
}

.payButton{
    padding:10px;
    background-color: $tecky-green;
    border-radius: 50px 50px 50px 50px;
    width: 100px;
    font-family: monospace;
    font-size: 20px;
    text-align: center;
    display: block;
    text-decoration: none;
    span{
        color: white;
    }
}

//Start - Scrolling with round angle
::-webkit-scrollbar {
    width: 14px;
}
 
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
    border-top-left-radius: 0;
     border-bottom-left-radius: 0;
}
 
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border-radius: 10px;
    border-top-left-radius: 0;
     border-bottom-left-radius: 0;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}
//End - Scrolling with round angle