@import '../shop/shop.module.scss';
.header{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 25px;
    div.title{
        padding-right: 20px;
        font-size: 1em;
        @media screen and (max-width:524px) {
            span{
                font-size:0.01em;
            }
        }
    }
}
.logoSection{
    //width:15%;
    img{
        padding: 10px 10px 10px 10px;
        filter: brightness(0) invert(1);
        //width: 80%;
    }
}

.loginSection{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width:30%;
    padding-right: 10px;
    font-weight: bold;
}

.loginButton{
    border-radius: 60px 60px 60px 60px;
    font-family: monospace;
    font-size: 22px;
    background-color:$tecky-cyan;
    color: white;
    padding: 10px 60px;
    cursor: pointer;
    border:none;
}

button.button{
    background-color: transparent;
}

button{
    span{
        font-size: 1em;
        @media screen and (max-width:524px) {
            span{
                font-size:0.01em;
            }
        }
    }
}