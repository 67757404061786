@import './shop.module.scss';
.dndInstruction{
    width:100%;
    span{
        color: white;
        padding-left: 10px;
        font-size: 18px;
        font-weight: bold;
    }
    padding-left: 10px;
    padding-right: 10px;
    div.dndInstructionBorder{
        border:$tecky-light-green solid 2px;
        border-radius: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}