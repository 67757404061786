@import './shop.module.scss';

div.shoppingCartSection{
    height: 100%;
    //padding-top: 10px;
    padding-left: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    div.shoppingCartTitle{
        width: 100%;
        border-radius: 10px;
        background-color: $tecky-green;
        padding-top: 10px;
        padding-bottom: 10px;
        span{
            color:white;
            padding-left: 10px;
            font-size: 25px;
            font-weight: bold;
        }
        @media screen and (max-width:524px) {
            span{
                color:white;
                padding-left: 10px;
                font-size:99%;
                font-weight: bold;
            }
        }
    }

    position: relative;
    div.shoppingCartList{
        height: 100%;
        border:$tecky-green solid 2px;
        border-radius: 10px;
        // margin-bottom: 25px;
        margin-top: 1vh;
        overflow:auto;
        position: relative;
        //bottom: 5px;
    }
    div.shoppingCartListSection{
        height: 100%;
        width: 100%;
        //position: absolute;
        bottom:10px;
        div.shoppingCartContent{
            border-radius: 10px;
            border:$tecky-cyan solid 1px;
            height:7vh;
            margin: 15px 5px 10px 5px;
            padding: 5px 5px 5px 5px;
            display: flex;
            flex-direction: row;
            align-items:center;
            div.shoppingCartContentImg{
                width:10%;
                height: 100%;
                img{
                    object-fit: contain;
                    max-width: 100%;
                    max-height: 100%;
                    width: auto;
                    height: auto;
                }
            }
            div.shoppingCartContentInfo{
                width: 60%;
                display: flex;
                flex-direction: column;
                padding-top: 1em;
                padding-bottom: 1em;
                div.shoppingCartContentInfoSpan{
                    width: 100%;
                    span{
                        padding-left: 0px;
                    }
                    font-size: 1em;
                    @media screen and (max-width:524px) {
                        span{
                          //font-size: 2px;
                          font-size:0.01em;
                        }
                    }
                }
            }
            div.shoppingCartContentQty{
                width: 30%;
                margin-right: 1vw;
                margin-left: 1vw;
                .shoppingCartContentQtyDrp{
                    width: 100%;
                    height: 5vh;
                }
            }
    
            div.shoppingCartContentDelBut{
                width:10%;
                button{
                    background-color: transparent;
                    border: none;
                    width: 80%;
                    height: 80%;
                }
                .shoppingCartContentDelButIco{
                    width: 80%;
                    height: 80%;
                    color:$tecky-green;
                    font-size: 5vh;
                }
            }
            //-moz-box-shadow:    3px 3px 5px 6px #ccc;
            //-webkit-box-shadow: 3px 3px 5px 6px #ccc;
            box-shadow:         2px 2px 2px 2px $tecky-light-green;
        }
    }
    
    div.shoppingCartListPlaceHolder{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        span{
            font-weight: bold;
            color: $tecky-green;
            font-size: 3em;
            opacity: 0.3;
            @media screen and (max-width:700px) {
                font-size: calc(2em - 1px);
            }
            @media screen and (max-width:524px) {
                font-size: calc(100% - 2px);
            }
        }
        text-align: center;
    }
}