@import './shop.module.scss';
.snackSection{
    width: 100%;
    height: 42vh;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding-left: 10px;
    padding-top: 5px;
    padding-right: 10px;
    div.topTitle{
        width: 100%;
        background-color: $tecky-cyan;
        border-radius: 10px;
        padding-top: 10px;
        padding-bottom: 10px;

        span{
            color: white;
            padding-left: 10px;
            font-size: 16px;
            font-weight: bold;
        }
    }
    div.topSectionContentBorder1{
        height: 100%;
        background-color:white;
        border-radius: 10px;
        margin-top: 1vh;
        margin-bottom: 10vh;
        display: flex;
        flex-direction: column;
        position: relative;
    }
}