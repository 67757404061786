@import '../shop/shop.module.scss';
@import '../stock/stock.module.scss';

div.title{
    display: flex;
    justify-content: center;
    font-size: 20px;
    margin-top: 20px;
}

div.contentSection{
    display: flex;
    justify-content: center;
}

div.rowStyle{
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
}