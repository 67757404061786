@import '../shop/shop.module.scss';

 div.topSection{
    background-image: url('/images/slant_3.png');
    background-size: 100% 100%;
    background-position:center;
    background-repeat: no-repeat;
    height: 12%;

    display: flex;
    flex-direction:column;
    div.row{
        display: flex;
        flex-direction: row;
        padding-top: 5px;
        align-items: center;
    }
}

div.overlapDiv{
    position: relative;
    width: 100%;
    height: 100%;
}

div.modalBox{
    position:absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    left: 0px;
    //background-color: black;
    opacity: 1;
    //z-index: 100;
    width: 100%;
    height: 100%;
    opacity: 0.6;
}

div.bottomSection{
    position:absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content:flex-end;
    align-items: center;
    margin-bottom: 125px;
    margin-right: 102px;
}

div.locationMenu{
    height: 100%;
    width: 100px;
    position: absolute;
    right:15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

div.stockInfoSection{
    position: absolute;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    flex-wrap: wrap;
    overflow-y: scroll;
}